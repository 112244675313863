import { LazyObserver } from "cadfem-modules/src/classes";


const selector = '[data-component="swiperSlider"]';

const opts = {
  loop: true,
  navigation: {
    nextEl: '.swiperSlider__arr_next',
    prevEl: '.swiperSlider__arr_prev',
  },
  pagination: {
    el: '.swiperSlider__pagination',
    type: 'fraction',
  },
};

(new LazyObserver({
  selector: selector,
  activeClass:'data-jumbotronMainSlider-lazy',
  observe: ({target, IO, activeClass}) => {
    import('swiper/js/swiper.min')
      .then( module => new module.default(selector, opts) )
      .then( () => target.removeAttribute(activeClass) )
      .catch(error => console.error(error));

    IO.unobserve(target);
  }
})
).init();
