import {LitElement, html,css } from 'lit-element';

// lazy
import './lazy/img';
import './lazy/bg';
import './lazy/styles';
import './lazy/SimpleSlider';
import './lazy/pswpGallery';

import './modules/cadfem';
import './modules/bootstrap';
// import APP from './APP';

/*const labelArrFilter = document.querySelectorAll('[data-component="scheduleFilter"]');
const labelArrFilter2 = document.querySelectorAll('[data-component="scheduleFilter2"]');
APP.mediator = {};

if (labelArrFilter.length && labelArrFilter2.length) {
  import(/!* webpackChunkName: "scheduleFilter2" *!/ './modules/local/scheduleFilter2')
    .then((module) => {
      APP.mediator.day = new module.default();
      APP.mediator.updateFrames = () => APP.mediator.day.updateFrames();
      APP.mediator.checkDays = () => APP.mediator.day.isAllUnchecked();
      APP.mediator.checkDaysSessions = () => APP.mediator.day.checkSessions();
    });
  import(/!* webpackChunkName: "scheduleFilter" *!/ './modules/local/scheduleFilter')
    .then((module) => {
      APP.mediator.filter = new module.default();
    });
}*/

/*const labelArrFilter = document.querySelectorAll('[data-component="scheduleFilter"]');
if (labelArrFilter.length) {
  import(/!* webpackChunkName: "scheduleFilter" *!/ './modules/local/scheduleFilter')
  .then((module) => {
    new module.default()
  });
}*/

function autoPlayYouTubeModal() { // TODO: make through bootstrap modal native methods
  const trigger = $('body').find('[data-toggle="modal"]');
  trigger.click(function triggerCb() {
    const theModal = $(this).data('target');
    const videoSRC = $(this).attr('data-video-url');
    const videoSRCauto = `${videoSRC}?autoplay=1&rel=0`;
    $(`${theModal} iframe`).attr('src', videoSRCauto);
    $(theModal).on('hidden.bs.modal', () => {
      $(`${theModal} iframe`).attr('src', videoSRC);
    });
  });
}
autoPlayYouTubeModal();

if (document.querySelectorAll('timer-el').length !== 0) import('./Timer');

import './images';

import('./additional/svgRequire');
